import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"


import appStoreLogo from '../images/appstore.png';
import googlePlayLogo from '../images/googleplay.png';

// import './pricing.css';

const DiscountablePrice = ({ price, discounted }) => (
  <>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ color: 'rgba(0,0,0,0.4)' }}>{price}</div>
        {discounted && <div style={{ position: 'absolute', left: '-10%', top: '50%', bottom: 0, right: 0, height: 3, width: '120%', background: 'red', transform: 'rotate(-5deg)' }} />}
      </div>
    </div>
    {discounted && <div style={{ color: '#f05454', fontWeight: 600, marginTop: 10 }}>{discounted}</div>}
  </>
);

export default () => (
  <Layout>
    <SEO title="Pricing" />

    <div>
      <div className="section">
        <div className="container">
          <div className="section-title section-title-wide no-bottom-space">
            <h6 className="subheading text-primary-1">A plan for everyone</h6>
            <h1 className="no-bottom-space">Our Pricing</h1>
            <p style={{ fontStyle: 'italic', fontSize: '1.2em', marginTop: '1em' }}>Buy for yourself or as a gift!</p>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid grid-thirds grid-align-bottom">
            <div className="card no-border">
              <div className="card-body card-body-large">
                <div className="text-center">
                  <h3>Free</h3>
                  <div className="text-muted">Always&nbsp;Free</div>
                </div>
                <div className="price-description">
                  <div className="pricing-bullet-text">Limited access to WiseGuide programs</div>
                </div>
                <ul className="w-list-unstyled">
                  <li>
                    <div className="text-block-9">Upgrade anytime</div>
                  </li>
                  <li>
                    <div className="text-block-8">Access to a limited selection of titles</div>
                  </li>
                  <li>
                    <div className="text-block-8">Individual title purchases available <Link to="/programs">here</Link> or in app</div>
                  </li>
                  <li>
                    <div className="text-block-8">1 Million+ Lives Improved</div>
                  </li>
                </ul>
                <div style={{ marginTop: 20 }}>
                  <div className="div-block-10">
                    <div style={{ width: '100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
                        <a href="https://apps.apple.com/us/app/wiseguide-for-healthy-habits/id1459536021" target="_blank" rel="noopener noreferrer"><img src={appStoreLogo} style={{ height: 40 }} alt="App Store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.wiseguide" target="_blank" rel="noopener noreferrer"><img src={googlePlayLogo} style={{ height: 40 }} alt="Google Play" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card no-border">
              <div className="handwriting-wrapper">
                <div className="card-label bg-primary-2">
                  <div className="text-small">Best Value</div>
                </div>
              </div>
              <div className="card-body card-body-large">
                <div className="text-center">
                  <h3>Annual</h3>

                  <DiscountablePrice price="$97/year - that's $8/month" />
                </div>
                <div className="price-description">
                  <div className="pricing-bullet-text">Unlock the full WiseGuide experience</div>
                </div>
                <ul className="w-list-unstyled">
                  <li>
                    <div className="text-block-9">7 day free trial</div>
                  </li>
                  <li>
                    <div className="text-block-9">Unlimited access to all 120+ programs</div>
                  </li>
                  <li>
                    <div className="text-block-9">Automatically renews annually</div>
                  </li>
                  <li>
                    <div className="text-block-9">Cancel any time</div>
                  </li>
                  <li>
                    <div className="text-block-9">1 Million+ Lives Improved</div>
                  </li>
                </ul>
                <div className="margin-top"><OutboundLink href="https://checkout.thewiseguideapp.com/checkout?add-to-cart=2438&quantity=1" className="button button-block w-button">Start 7 day free trial</OutboundLink></div>
              </div>
            </div>
            <div className="card no-border">
              <div className="card-body card-body-large">
                <div className="text-center">
                  <h3>Monthly</h3>

                  <DiscountablePrice price="$14.99/month" />
                </div>
                <div className="price-description">
                  <div className="pricing-bullet-text">Unlock the full&nbsp;WiseGuide experience</div>
                </div>
                <ul className="w-list-unstyled">
                  <li>
                    <div className="text-block-9">7 day free trial</div>
                  </li>
                  <li>
                    <div className="text-block-9">Unlimited access to all 120+ programs</div>
                  </li>
                  <li>
                    <div className="text-block-9">Automatically renews monthly</div>
                  </li>
                  <li>
                    <div className="text-block-9">Cancel any time</div>
                  </li>
                  <li>
                    <div className="text-block-10">1 Million+ Lives Improved</div>
                  </li>
                </ul>
                <div className="margin-top"><OutboundLink href="https://checkout.thewiseguideapp.com/checkout?add-to-cart=2439&quantity=1" className="button button-block bg-gray-4 w-button">Start 7 day free trial</OutboundLink></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-gray-4">
        <div className="container">
          <div className="section-title">
            <h3 className="h2">It's never been easier to improve your life</h3>
            <div>If you're ready to take the next step to improve your life, then choose a plan above and you’ll be guided through the process. And remember, whatever choice you make it’s free to start! So start now and begin to discover how it will change your life!</div>
          </div>
        </div>
      </div>

      <div className="section light-orang">
        <div className="container">
          <div className="row row-split-content">
            <div className="content-width-small">
              <h6 className="subheading text-primary-1">Thousands of glowing reviews </h6>
              <h3 className="h1">Here are a few reviews from people like you</h3>
              <div>Tens of thousands of people have reviewed our programs over the years. We highlighted some of our favorite success stories. </div>
              <Link to="/case-studies/" className="button bg-white margin-top w-inline-block">
                <div>More testimonials</div>
              </Link>
            </div>
            <div className="content-width-large">
              <div className="w-layout-grid grid-halves">
                <div className="w-layout-grid grid-rows">
                  <div data-w-id="e96e20f9-8a42-a8e6-919b-ec841f8b5eb5" className="card no-border">
                    <div className="card-body">
                      <div className="text-small">I listen to this almost every evening before I go to bed and it does relax me. I feel the relaxation and stress reduction as I am listening to it</div>
                    </div>
                  </div>
                  <div data-w-id="e96e20f9-8a42-a8e6-919b-ec841f8b5ec2" className="card no-border">
                    <div className="card-body">
                      <div className="text-small">I would give this ten stars if I could! This app is fantastic, and truly relaxes your whole body. It also helps me to get deep sleep. This is a app I will be listening to all the time. Finally a app that is perfect!</div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-rows">
                  <div data-w-id="e96e20f9-8a42-a8e6-919b-ec841f8b5ed0" className="card no-border">
                    <div className="card-body">
                      <div className="text-small">I have always had a real problem with having negative thoughts constantly. But since I started listening to Bob's app, I notice that I don't immediately have a negative thought about everything. I actually can wake up in the morning and think something positive. I actually can envision a positive outcome more often.</div>
                    </div>
                  </div>
                  <div data-w-id="e96e20f9-8a42-a8e6-919b-ec841f8b5edd" className="card no-border">
                    <div className="card-body">
                      <div className="text-small">I really recommend this app, I think most if not all of US need a positive thinking and outlook in life especially at times like these.!!!!!!!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="content-width-extra-large extra-larger">
              <div className="horizontal-quote"><img src="/images/5ddda475ad8b11939bd180ef_Bob%20G.png" alt="" className="avatar avatar-large horizontal-quote-avatar" />
                <div>
                  <blockquote>For over 40 years Bob has been helping people learn to use the power of their own minds to change their lives</blockquote>
                  <div>
                    <div className="handwriting text-primary-1">Bob Griswold</div>
                    <div>Founder of The WiseGuide App</div>
                  </div>
                  <div className="row row-justify-center row-align-center" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
